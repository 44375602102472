import React, {Component} from "react";
const classes = require('../../App.module.scss');

class OpenHours extends Component {
  state = {
    hour: null, 
    day: null,
    minutes: null
  }
  
  componentDidMount() {
    this.getHour();
    this.getDay();
    this.getMinutes();
  }

  getHour = () => {
    const date = new Date();
    const hour = date.getHours();
    this.setState({
      hour
    });
  }
  
  getDay = () => {
    const date = new Date();
    const day = date.getDay();
    this.setState({
      day
    });
  }
  
  getMinutes = () => {
    const date = new Date();
    const minutes = date.getMinutes();
    this.setState({
      minutes
    });
  }
  
  render() {
    const {hour} = this.state;
    const {day} = this.state;
    const {minutes} = this.state;

    // if ((day === 0 && hour>12) || day === 1 || day === 2 || day === 3) {
    //   console.log("day rules set 1");
    // } 
    // else if ((day === 3 && hour>12) || day === 4 || day === 5) {
    //   console.log("day rules set 2");
    // }
    // else {
    //   console.log("day rules set 3");
    // }

      
    return (
      <div id="openHours" className={classes.OpenHours}>
        {/* Mon-Thurs -- Open 9:00am - 4:30pm */}
        {day >= 1 && day <= 4 && hour >= 9 && (hour < 16 || (hour === 16 && minutes < 30)) ? 'Open Now' : null }
        {/* Mon-Thurs -- Closed */}
        {day >= 1 && day <= 4 && (hour < 9 || (hour > 16 || (hour === 16 && minutes >= 30))) ? 'Closed til 9am' : null }
        {/* Friday -- Open 9:00am - 2:30pm*/}
        {day === 5 && hour >= 9 && (hour < 14 || (hour === 14 && minutes > 30)) ? 'Open Now' : null}
        {/* Friday -- Closed */}
        {day === 5 && hour < 9 && (hour > 14 || (hour === 14 && minutes >= 30)) ? 'Closed til Monday' : null}
        {/* Weekends */}
        {day === 0 || day === 6 ? 'Closed til Monday' : null}
      </div>
    );
  }
}

export default OpenHours;