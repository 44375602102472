import React, {Component} from 'react';
import { withRouter, Route, Switch } from "react-router-dom";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import HeaderAlt from "./components/HeaderAlt/HeaderAlt";
import Footer from "./components/Footer/Footer";
import Home from "./hoc/Pages/Home/Home";
import Contact from "./hoc/Pages/Contact/Contact";
import Products from "./hoc/Pages/Products/Products";
import About from "./hoc/Pages/About/About";
import FAQ from "./hoc/Pages/FAQ/FAQ";
const classes = require('./App.module.scss');

class App extends Component {
  render() {
    let routes = (
      <Switch>
        <Route exact={true} path="/" component={Home} />
        <Route path="/contact" component={Contact} />
        <Route path="/products" component={Products} />
        <Route path="/about" component={About} />
        <Route path="/faqs" component={FAQ} />
      </Switch>
    );
    
    const HeadStyle = () => {
      if (window.location.pathname === '/') {
        return (
          <HeaderAlt HeaderType="HomeHeader" navType="homeHeaderAltNav" />
        );
      }
      return (
        <HeaderAlt HeaderType="Reg" navType="headerAltNav"  />
      )
    }
    const SneakyHeader = withRouter(HeadStyle);
    
    
    
    
    
    return (
      <div className={classes.AllGreenWrap}>
        <SneakyHeader />
        {/* <Content>{routes}</Content> */}
        <main className={classes.Content}>{routes}</main>
        
        <Footer />
        <ScrollUpButton
          ContainerClassName={classes.ToTopBtn}
          TransitionClassName="MyOverRideTransitionedClass"
          style={{ fill: 'rgb(255,255,255)', color: '#008AAB', backgroundColor: '#02452b', width: '20px', padding: '0px 8px 2px' }}
          EasingType='linear'
        />
      </div>
    );
  }
}

export default App;
