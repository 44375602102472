import React from "react";
import { Helmet } from "react-helmet";
const classes = require('./Products.module.scss');
const imagePet = require('../../../assets/images/pet-prod.jpg');
const imageSkin = require('../../../assets/images/ag-products9.jpg');
const dropper = require('../../../assets/images/oil.jpg');
const lotion = require('../../../assets/images/lotion.jpg');
const imageOil = require('../../../assets/images/ag-products2.jpg');
const imageOilMob = require('../../../assets/images/ag-products2Long.jpg');

const text0 = (
  <div>
    <h1>Our <span>Products</span></h1>
    <p>The All Green Alabama Medical team has researched all of the different CBD manufactures, and chosen only a 
      select few to sell at our clinic. We offer products that contain <b>less than .3% THC</b>, as well as products containing <b>zero THC.</b>
    </p>
    <p><b>We do not offer CBD that can be inhaled. 
      Inhalation of vapor oils and chemical byproducts carry unknown health risks, and is therefore, not recommended.</b></p>
  </div>
);

const textOil = (
  <div>
    <h3>Oils &amp; Extracts</h3>
    <p>
      The already long list of benefits associated with using CBD oils is continuously growing.
      We carry an assortment of <b>all-natural</b> oils that provide many amazing results, such as <b>boosting</b> your immune and 
      central nervous systems, <b>enhancing</b> your performance and recovery, and <b>increasing</b> your energy and mental focus.   
    </p>
    
    <p className={classes.directions}>
      <b>How to use:</b><br/>
      Start with 1/3 dropper. Hold under the tongue for at least 20 seconds before swallowing.
      You may slowly increase that amount daily until you find your preferred dosage.
      Adults can take a full dropper up to 3 times per day.
    </p>
  </div>
);

const textTopical = (
  <div>
    <h3>Topicals</h3>
    <p>
      We offer topicals as a <b>natural</b> way to <b>alleviate pain</b> caused by sore muscles and joints.
      Apply a soothing topical to affected areas of your body to <b>tackle aches and discomfort,</b> so you can <b>stay healthy and active. </b>
      <span>Topicals are available with and without THC.</span>
    </p>
    
    <p className={classes.directions}>
      <b>How to use:</b><br/>
      Massage into clean, dry skin. External use only.
      Apply 1-3 times daily.
    </p>
  </div>
);

const textSkin = (
  <div>
    <h3>Skin Care</h3>
    <p>Our lip balm and body butter are the <b>soothing essentials</b> your skin care routine didn't know it was missing. 
      Take advantage of all the benefits these products offer while you <b>restore, revitalize, and moisturize.</b>
    </p>
  </div>
)

const textPet = (
  <div>
    <h3>Pets</h3>
    <p>
      We also have effective, <b>all-natural</b> CBD solutions created specifically for your furry best friends, so 
      they can <b>stay healthy, strong, and active.</b> Products and dosages vary based on your pet's size and needs.
    </p>
        
    <p className={classes.directions}>
    <b>How to use:</b><br/>
    Take orally or with food.
    Start with 3-4 drops. 
    Gradually work up to the suggested use-by-weight listed on the bottle, or until you obtain the desired result.
    </p>
  </div>
);

const products = () => {
  return (
    <div className={classes.ProductsPage}>
      <Helmet>
        <title>CBD Products in Foley, Al | All Green Alabama Medical</title>
        <meta name="description" content="The All Green Alabama Medical team has carefully researched and selected our CBD manufactures. You can learn more about the products we carry on this page." />
      </Helmet>
      
      <div className={classes.colorPageHead}>
        <div className={classes.medDef}>
          <div className={classes.textBox}>{text0}</div>
          
          <div className={classes.imgInline}><img src={dropper} className={classes.imgResponsive} alt="Girl using CBD oil dropper" /></div>
        </div>
      </div>
      
      <div className={classes.colorPageColl}>
        <div className={classes.insideAlt}>
          <div className={classes.oilBlock}>
            <div className={classes.textBox}>{textOil}</div>
            <div className={classes.oilImg}>
              <img src={imageOil} className={classes.imgResponsive} alt="Variety of Receptra CBD oils at All Green Alabama Medical" />
              <img src={imageOilMob} className={[classes.imgResponsive, classes.mobile].join(' ')} alt="Selection of Receptra CBD oils" />
            </div>
          </div>
          
          <div className={classes.topiBlock}>
            <div className={classes.textBox}>{textTopical}</div>
          </div>
          <div className={classes.topiImg}><img src={imageSkin} className={classes.imgResponsive} alt="Receptra CBD topicals, body butter, and lip balm" /></div>
          
          <div className={classes.skinImg}><img src={lotion} className={classes.imgResponsive} alt="Woman's finger dipped in CBD topical" /></div>
          <div className={classes.skinBlock}>
            <div className={classes.textBox}>{textSkin}</div>
            <img src={lotion} className={[classes.imgResponsive, classes.mobile].join(' ')} alt="All Green Alabama Medical, CBD topical" />
          </div>
          
          
          <div className={classes.petBlock}>
            <div className={classes.textBox}>{textPet}</div>
          </div>
          <div className={classes.petImg}><img src={imagePet} className={classes.imgResponsive} alt="Dog standing on the shoreline" /></div>
          
        </div>
      </div>
    </div>
  );
};

export default products;
