import React from "react";
import { Link } from "react-router-dom";
import PageHeaderGreen from "../../../components/PageHeaderGreen/PageHeaderGreen";
import Accordion from "../../../components/Accordion/Accordion";
const {Helmet} = require('react-helmet');
const classes = require('./FAQ.module.scss');
const PageHeaderAltImage = require('../../../assets/images/hemp-plant.jpg');
const imageLI = require('../../../assets/images/docDrop.jpg');
const makeup = require('../../../assets/images/cbd-structure.png');
const productsImg = require('../../../assets/images/ag-products6.jpg');
const petsImg = require('../../../assets/images/beachPet.jpg');
const petsImg2 = require('../../../assets/images/ag-products1.jpg');

const titleH1 = 
  (<div><span>What</span> Would You <br/> Like To Know?</div>);

const whatIsCBD = (
  <div id="sw1" key="whatIsCBD" className={classes.faqWhat}>
    <div className={classes.text}>
      <p><b>CBD,</b> short for <span>cannabidiol,</span> is an active compound found in the cannabis plant. 
      CBD is extracted from hemp, and can be used in a variety of products.</p> 
      
      <p>CBD is <span>non-psychoactive,</span> and only contains traces of <b>up to 0.3%</b> of THC, which is 
      the legal limit for THC content in CBD products classified as hemp.</p>
    </div>
    <img src={makeup} alt="CBD chemical makeup" className={[classes.imgResponsive, classes.makeup].join(' ')} />
  </div>
);

const how = (
  <div id="sw2" key="how">
    <p>CBD reacts with the body through the <b>The Endocannabinoid System (ECS). </b></p>
      
    <p>The ECS is a complex system made up of millions of <span>cannabinoid receptor sites,</span> and is 
    <span> essential</span> to the <span>health and healing</span> of the human body. 
    The ECS helps to regulate immune function, sleep, mood and anxiety, motor control, 
    pain, memory, appetite and metabolism, reproduction and fertility, and more.
    CBD products give you access to the full potential of cannabinoids.</p>
    
    <p><b>The ECS exists and is active in your body</b> <span>even if you don’t use CBD products.</span></p>
  </div>
);

const whyUs = (
  <div id="sw3" key="whyUs">
    <p><b>All Green Alabama Medical is a medical clinic owned by medical professionals, <span>not just a store that sells CBD products. </span>
    We are <span>Baldwin County’s first Medical Grade Cannabinol seller</span>, recommended and endorsed by local doctors.</b></p> 
    <div className={classes.spacer1} />
    <Link to="/about" className={classes.agLink}>Learn More</Link>
    <div className={classes.clearfixBtn} />
  </div>
);

const products = (
  <div id="sw4" key="products" className={classes.faqProd}>
    <div className={classes.text}>
      <p>We have researched all of the different CBD manufactures, and have chosen only a select few to sell at our clinic.
        Our products include, but aren't limited to <b>extracts, tincture oils, topicals, lip balms, body butters, and pet products.</b>
      </p>
      <div className={classes.spacer1} />
      <Link to="/products" className={classes.agLink}>Learn More</Link>
      <div className={classes.clearfixBtn} />
    </div>
    <img src={productsImg} alt="Receptra CBD products on display at All Green Alabama Medical clinic" className={[classes.imgResponsive, classes.prod].join(' ')} />
  </div>
);

const thc = (
  <div id="sw5" key="thc">
    <p>In order for CBD to be classified as hemp, it cannot legally contain more than .3% THC. We offer products with <b>less than .3% THC,</b> and products with <span>zero THC.</span></p>    
  </div>
);

const vape = (
  <div id="sw6" key="vape">
    <p><span>We do not offer CBD that can be inhaled. </span>
    Inhalation of vapor oils and chemical byproducts carry <b>unknown health risks,</b> and is therefore, not recommended.</p>
  </div>
);

const pets = (
  <div id="sw7" key="pets" className={classes.faqPet}>
    <div className={classes.text}>
      <p><b>Yes.</b> We offer different solutions for pets based on their weight.</p>
      <img src={petsImg2} alt="Dog running on the beach" className={[classes.imgResponsive, classes.textImg].join(' ')} />
    </div>
    <img src={petsImg} alt="Receptra CBD oils for pets, sold at All Green Alabama Medical" className={[classes.imgResponsive, classes.pet].join(' ')} />
  </div>
);

const drugTest = (
  <div id="sw9" key="drugTest">
    <p><b>Products with THC contain less than .3%.</b><br/>
      However, if your place of work does random drug testing, we can send your employer a sheet listing why you might test positive for THC.
    </p>
  </div>
);


const accItems = [
  {id: 1, switchId1: 'sw1', switchId2: 'sw21', title: 'What is CBD?', content: [whatIsCBD], open: "uk-open"},
  {id: 2, switchId1: 'sw2', switchId2: 'sw22', title: 'How does it work?', content: [how], extraVa: "long"},
  {id: 3, switchId1: 'sw3', switchId2: 'sw23', title: 'What makes you different?', content: [whyUs]},
  {id: 4, switchId1: 'sw4', switchId2: 'sw24', title: 'What kind of products do you carry?', content: [products]},
  {id: 5, switchId1: 'sw5', switchId2: 'sw25', title: 'Is there THC in CBD products?', content: [thc]},
  {id: 6, switchId1: 'sw6', switchId2: 'sw26', title: 'Do you offer vape products?', content: [vape]},
  {id: 7, switchId1: 'sw7', switchId2: 'sw27', title: 'Do you offer products for Pets?', content: [pets]},
  {id: 9, switchId1: 'sw9', switchId2: 'sw29', title: 'Will CBD products make me fail a drug test?', content: [drugTest]}
];

const qList = accItems.map((accItem) =>
  <li key={accItem.id} className={classes.oen}>
    <a className={classes.b} href={["#" + accItem.switchId1]} data-uk-scroll>{accItem.title}</a>
  </li> 
);

const fAQ = () => {
  return (
    <div className={classes.FAQPage}>
      <Helmet>
        <title>Medical Grade CBD in Baldwin County, Al | All Green Alabama Medical</title>
        <meta name="description" content="Read through our faq's for more information on the All Green clinic and CBD products. Contact us if you have a question that isn't answered on this page." />
      </Helmet>
      
      <div className={classes.hdStrip}>
        <div className={classes.medDef}>
          <PageHeaderGreen addStyle="imageMd" headerTitle={titleH1} image={PageHeaderAltImage} imageAlt="Hemp plant, close up" />
        </div>
      </div>
      
      <div className={classes.faqSection}>
        <div className={classes.colorIntro}>
          <div className={classes.med3Def}>
            <h2>Frequently Asked Questions</h2>
            <p>Read through our list of FAQs below for more information. <br/>
            <Link to="/contact">Contact us </Link> if you have a question that isn't answered on this page.</p>
          </div>
        </div>
        
        <div className={classes.colorFaq} id="question-wrap" data-uk-grid>
          <div className={[classes.medDef, classes.float].join(' ')}>
            <div className={classes.questions}>
              <ul className={classes.quests}  data-uk-sticky="offset:50; bottom: #question-wrap;">
                {qList}
              </ul>
            </div>
            
            <div className={classes.qList}>
              <img src={imageLI} alt="Medical professional dripping CBD oil" className={[classes.imgResponsive, classes.imgAbs].join(' ')} />
              <Accordion accItems={accItems} altClass="sizeColor" altClass2="openLgAcc" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default fAQ;