import React from "react";
import { Helmet } from "react-helmet";
import PageHeaderGreen from "../../../components/PageHeaderGreen/PageHeaderGreen";
const classes = require('./About.module.scss');
const PageHeaderAltImage = require('../../../assets/images/ag-shop1.jpg');
const PageHeaderMob = require('../../../assets/images/ag-shop1Mobile.jpg');
const NMimage = require('../../../assets/images/ag-staffNM.jpg');
const NMimage2 = require('../../../assets/images/ag-staffNM2.jpg');
const LRimage = require('../../../assets/images/ag-staffLR.jpg');
const LRimage2 = require('../../../assets/images/ag-staffLR2.jpg');
const agWaiting = require('../../../assets/images/ag-shop2.jpg');
const agWaiting2 = require('../../../assets/images/ag-shop3.jpg');
const agPhoto3 = require('../../../assets/images/ag-products7.jpg');
const agPhoto4 = require('../../../assets/images/ag-hoodie3.jpg');
const agPhoto5 = require('../../../assets/images/ag-products5.jpg');

const AltPageHeader = (
  <div className={classes.aboutGreen}>
    <h1>About <span>Us</span></h1>
    <h2>
      All Green Alabama Medical is a <b>medical clinic</b> owned and operated by <b>medical professionals,</b> an MD and RN. 
      We are Baldwin County’s <b>first Medical Grade Cannabinol seller</b> recommended and <b>endorsed by local doctors.</b>
    </h2>
    
    <div className={classes.clearBorderWhite}/><div className={classes.clearfix}/>
    <a className={[classes.agLink, classes.center].join(' ')} href="#bios" data-uk-scroll>Meet the Owners</a>
  </div>
);

const bioNM = (
  <div>
    <h3>Nidal <span>Morrar,</span> MD</h3>
    <p>Nidal Morrar, MD is board certified in Family Practice, Hospice and Palliative Care. 
      He is currently a Practitioner in Family Medicine and is an active member of the American Academy of Family Physicians. 
      Dr. Morrar serves as Medical Director for Asera Care Hospice, Physician Advisor for South Baldwin Regional Medical Center, 
      and Principal Investigator for G & L Research. He is also Co-founder and Medical Director of G & L Weight Loss.
    </p>
    <ul className={classes.bioAcc} data-uk-accordion>
      <li>
        <button className={[classes.bioAccTitle, "uk-accordion-title"].join(' ')}>
          Read <span className={classes.more}>More</span> <span className={classes.less}>Less</span>
        </button>
        <div className={[classes.bioAccContent, "uk-accordion-content"].join(' ')}>
          <p>Dr. Morrar has given many presentations on independent research topics such as high-risk obstetrics, 
            breast and testicular cancer, and specialized childhood abuse treatments. He is dedicated to combat Alabama’s high obesity 
            rate, offer specialized care when needed, and improve the overall well-being of his patients.
          </p>
          <p>Although his work keeps him very busy, he still has time to enjoy his family and hobbies. Dr. Morrar is a nationally ranked 
            member of the U.S. Table Tennis Team Association, and is SCUBA Divemaster certified. Dr. Morrar, a true Trekkie, 
            enjoys rebuilding classic cars, flying remote control aircrafts, and collecting vintage arcade and pinball machines.
          </p>
        </div>
      </li>
    </ul>
  </div>
);

const bioLR = (
  <div>
    <h3>Lindsay U. <span>Russell,</span> RN</h3>
    <p>Lindsay U. Russell received her BSN from Samford University’s Ida V. Moffett School of Nursing in 1999. 
    Post-graduation, Lindsay worked as a Registered Nurse in a hospital setting, where she developed a passion for pharmacology and research. 
    This passion drove her to open G &amp; L Research, in the hopes of bringing new treatments to the residents of Baldwin County. 
    Lindsay’s impact on the community expanded with the opening of G &amp; L Weight Loss in 2015.</p>
    
    <ul className={classes.bioAcc} data-uk-accordion>
      <li>
        {/* <a className={[classes.bioAccTitle, "uk-accordion-title"].join(' ')} href="#">Read More</a> */}
        <button className={[classes.bioAccTitle, "uk-accordion-title"].join(' ')}>
          Read <span className={classes.more}>More</span> <span className={classes.less}>Less</span>
        </button>
        
        <div className={[classes.bioAccContent, "uk-accordion-content"].join(' ')}>
          <p>After more than ten years of experience as a Clinical Research Coordinator and entrepreneur, 
          Lindsay has created a new business dedicated to improving the mental and physical health of residents. 
          Lindsay attributes her success to support from her family and friends. As one of Baldwin County’s leading female entrepreneurs, 
          Lindsay Russell continues to dedicate herself to improving medical care provided to Baldwin County residents.</p>
        </div>
      </li>
    </ul>
  </div>
);

const about = () => {
  return (
    <div className={classes.AboutPage}>
      <Helmet>
        <title>CBD Clinic in Foley, Al | About All Green Alabama Medical</title>
        <meta name="description" content="All Green Alabama Medical is Baldwin County’s first Medical Grade CBD seller, recommended and endorsed by local doctors." />
      </Helmet>
      
      
      <div className={classes.inside}>
        <PageHeaderGreen addStyle="productPage" headerText={AltPageHeader} image={PageHeaderAltImage} imageAlt="All Green Alabama Medical front door" />
        <img src={PageHeaderMob} alt="All Green Alabama Medical, front door" className={[classes.imgResponsive, classes.mobileHeadImg].join(' ')} />
      </div>
      <div className={classes.clearfix} />
    
      <div className={classes.aboutUs}>
        <div className={classes.insideNrw}>
          <div className={classes.fullRow}>
            <h4><b>CBD</b> Solutions <br/> from <b>Medical Professionals</b></h4>
            
            <div className={classes.tbox}>
              <p>All Green Alabama Medical is located in Foley, Alabama.
              We give customers the option to keep a simple medical chart on file with us. 
              This would include a brief medical history, a list of current medications, and an ongoing record of products they purchase from our storefront.</p>
              
              <p>Our team of medical experts has researched all the different CBD manufactures, and has chosen only a select few to sell at our clinic.
                All Green Alabama Medical offers a variety of different products, all of which <b>contain less than .3% THC,</b> including some containing <b>zero</b> THC.
                <b> We do not offer any vape, flower, smoking products due to the health implications.</b>
              </p>
              
              <div className={[classes.clearfix, classes.btn].join(' ')} />
              <a className={classes.agLink} href="#bios" data-uk-scroll>Our Products</a>
            </div>
          </div>
        </div>
      </div>
      
      <div className={classes.photoRow}>
        <div className={classes.insideXl} data-uk-lightbox="animation: slide;">
          <div className={classes.collRow}>
            <a href={agWaiting} data-caption="Front Desk &amp; Product Display Case"><img src={agWaiting} className={[classes.imgResponsive, classes.waiting1].join(' ')} alt="All Green front desk and CBD product display" /></a>
            <a href={agPhoto3} data-caption="Receptra Products"><img src={agPhoto3} className={[classes.imgResponsive, classes.prod1].join(' ')} alt="Group of Receptra CBD products" /></a>
          </div>
          <div className={classes.collRow2}>
            <a href={agPhoto4} data-caption="All Green Alabama Medical Hoodie"><img src={agPhoto4} className={[classes.imgResponsive, classes.hoodie].join(' ')} alt="All Green Alabama Medical Hoodie" /></a>
          </div>
          
          <div className={classes.collRow3}>
            <a href={agPhoto5} data-caption="Receptra Targeted Topicals"><img src={agPhoto5} className={[classes.imgResponsive, classes.prod2].join(' ')} alt="Group of Receptra CBD Topicals" /></a>
            <a href={agWaiting2} data-caption="Our Waiting Room"><img src={agWaiting2} className={[classes.imgResponsive, classes.waiting2].join(' ')} alt="All Green clinic waiting area" /></a>
          </div>
        </div>
      </div>
      <div id="bios" className={classes.titleRow}><h3><span>Our</span> Medical Staff</h3></div>
    
      
      <div className={classes.BiosAlt}>
        <div className={classes.inside}>
          <div className={classes.BioRow}>
            <div className={classes.image}>
              <img src={NMimage} alt="All Green owner, Nidal Morrar, MD" className={[classes.imgResponsive, classes.imgFull].join(' ')} />
              <img src={NMimage2} alt="All Green owner, Nidal Morrar, MD" className={[classes.imgResponsive, classes.imgMob].join(' ')} />
            </div>
            
            <div className={classes.text}>
              <div className={classes.textBox}>
                {bioNM}
              </div>
            </div>
          </div>
          
          <div className={[classes.BioRow, classes.btm].join(' ')}>
            <div className={classes.image}>
              <img src={LRimage} alt="All Green owner, Lindsay Russell, RN" className={[classes.imgResponsive, classes.imgFull].join(' ')} />
              <img src={LRimage2} alt="All Green owner, Lindsay Russell, RN" className={[classes.imgResponsive, classes.imgMob].join(' ')} />
            </div>
            
            <div className={classes.text}>
              <div className={classes.textBox}>
                {bioLR}
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default about;
