import React from "react";
import { Helmet } from "react-helmet";
import Social from "../../Social/Social";
import { Link } from "react-router-dom";
// import PageHeaderGreen from "../../../components/PageHeaderGreen/PageHeaderGreen";
const classes = require('./Contact.module.scss');
const PageHeaderAltImage = require('../../../assets/images/ag-staff4.jpg');

const contact = () => {
  return (
    <div className={classes.ContactPage}>
      <Helmet>
        <title>CBD Products in Baldwin County | Contact All Green Alabama Medical</title>
        <meta name="description" content="All Green Alabama Medical, located in Foley, Al, is here to help. Contact us if you have any questions about our clinic or our CBD products." />
      </Helmet>
      
      <div className={classes.style1}><div className={classes.xlOverlay}>
        <div className={classes.contactHead}>
          <div className={classes.med2Def}>
            <div className={classes.tblock}>
              <h1>Contact <span>Us</span></h1>
            </div>
            
            <div className={classes.image}><img src={PageHeaderAltImage} alt="Owners of All Green Alabama Medical, CBD clinic" className={classes.imgResponsive} /></div>
          </div>
        </div>
        
        <div className={classes.contactWrap}>
          <div className={classes.med2Def}>
            <div className={classes.text}>
              <h2 className={classes.black}>We are here <br/> <span>to</span> <span>help</span></h2>
              <div className={classes.inner}>
                <p>We want our customers to feel <b>comfortable</b> and <b>confident,</b> in us and the products they purchase. 
                Please don't hesitate to contact us if you have any questions. 
                You can also check out our <Link to="/faq"><b>FAQ's</b></Link> for more information.</p>
              </div>
            </div>

            <div className={classes.info}>
              <div className={classes.cBlockL}>
                <p className={classes.label}>Address:</p>
                <p>1600 N. McKenzie Street,
                Foley, Al 36535</p>
                <div className={classes.spacer1} />
                <a 
                  // href="https://wego.here.com/directions/mix//All-Green-Alabama-Medical,-1526-N-McKenzie-St,-Foley,-AL-36535,-USA:e-eyJuYW1lIjoiQWxsIEdyZWVuIEFsYWJhbWEgTWVkaWNhbCIsImFkZHJlc3MiOiIxNjAwIE5vcnRoIE1jS2VuemllIFN0cmVldCwgRm9sZXksIEFsYWJhbWEiLCJsYXRpdHVkZSI6MzAuNDI1NDA5MSwibG9uZ2l0dWRlIjotODcuNjgzMDc1MSwicHJvdmlkZXJOYW1lIjoiZmFjZWJvb2siLCJwcm92aWRlcklkIjoxMTkwNzYzMjk0OTQ5OTV9?map=30.42541,-87.68308,15,normal&fb_locale=en_US" 
                  href="https://www.google.com/maps/dir//all+green+alabama+medical/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x889a0d4d9e845cf5:0xef8b7590d0f57454?sa=X&ved=2ahUKEwi_6-CFo_nmAhULbKwKHS1VCJYQ9RcwFHoECBYQDQ" 
                  target="_blank" 
                  className={classes.agLink}
                  rel="noopener noreferrer"
                ><b>Get Directions</b></a> <div className={classes.clearfixBtn} />
              </div>
              
              <div className={classes.cBlockL}>
                <p className={classes.label}>Phone:</p>
                <p><a href="tel: +12519432980">251-943-2980</a></p>
              </div>
              
              <div className={classes.cBlock}>
                <p className={classes.label}>Email:</p>
                <p>allgreenalabamamedical@gmail.com</p>
              </div>
              
              <div className={classes.cBlock}>
                <p className={classes.label}>Hours:</p>
                <p><b>Monday - Thursday:</b> 9:00am - 4:30pm <br/> <b>Friday:</b> 9:00am - 2:30pm</p>
              </div>
              
              <div className={classes.cBlock}>
                <p className={classes.label}>Follow Us:</p>
                <Social />
              </div>
            </div>
          
          </div>
        </div>
      </div></div>
    </div>
  );
};

export default contact;
