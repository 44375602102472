import React from "react";
const classes = require('./Social.module.scss');

const social = (props) => {
  return (
    <ul className={[classes.Social, classes[props.Colors]].join(' ')}>
      <li><a href="https://www.facebook.com/allgreenalabamamedical/" target="_blank" rel="noopener noreferrer">
        <span className={classes.SocialIcon} aria-label="All Green Alabama Medical Facebook Link" uk-icon="icon: facebook"></span>
      </a></li>

      {/* <li><a href="https://www.instagram.com/explore/locations/119076329494995/all-green-alabama-medical/" target="_blank" rel="noopener noreferrer">
        <span aria-label="All Green Alabama Medical Instagram Link" uk-icon="icon: instagram"></span>
      </a></li> */}

      {/* <li><a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
        <span aria-label="All Green Alabama Medical Twitter Link" uk-icon="icon: twitter"></span>
      </a></li>

      <li><a href="https://www.youtube.com/user/" target="_blank" rel="noopener noreferrer">
        <span aria-label="All Green Alabama Medical Youtube Link" uk-icon="icon: youtube"></span>
      </a></li>

      <li><a href="https://plus.google.com" target="_blank" rel="noopener noreferrer">
        <span aria-label="All Green Alabama Medical Google Plus Link" uk-icon="icon: google-plus"></span>
      </a></li> */}
    </ul>
  );
};

export default social;
