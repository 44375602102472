import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const classes = require('./Home.module.scss');
const noBg = require('../../../assets/images/bottle-dropper.png');
const layoutImg1 = require('../../../assets/images/ag-products8.jpg');
const plants = require('../../../assets/images/plants2.jpg');
const prod = require('../../../assets/images/single.png');
const home1 = require('../../../assets/images/ag-shop1Home.jpg');
const homePlax = require('../../../assets/images/plants1.jpg');

const agDets = (
  <div className={classes.dets}>
    <p>All Green Alabama Medical is a <b>medical clinic</b> located in Foley, Alabama that offers CBD products. 
    We are owned and operated by <b>medical professionals,</b> an MD and RN. Our goal is to provide our customers with 
    <b> high-quality products, and information</b> on the benefits of CBD.
    </p>
    <div className={classes.clearfixBtn}></div><Link to="/about" className={classes.agLink}>Learn More</Link>
  </div>
);

const home = () => {
  return (
    <div className={classes.HomePage}>
      <Helmet>
        <title>Medical Grade CBD in Foley, AL | All Green Alabama Medical</title>
        <meta name="description" content="All Green Alabama Medical is a medical clinic in Foley, Alabama that offers CBD products recommended by local doctors." />
      </Helmet>
      
      <div className={[classes.curveGreen, "uk-background-cover"].join(' ')} data-uk-parallax="bgy: -150" style={{backgroundImage: "url(" + [homePlax] + ")" }}>
        <div className={classes.med3Def}>
          <h1>All Green <br/>Alabama <span>Medical</span></h1>
          <h2>Baldwin County’s first <span>Medical Grade Cannabinol</span> seller <span>recommended and endorsed</span> by local doctors.</h2>
          <img src={noBg} alt="All Green Alabama Medical CBD oil bottle and dropper" className={classes.imgResponsive} />
        </div>
      </div>
            
      <div id="whoAreWe" className={classes.headline}>
        <div className={classes.insideAlt}> 
          <div className={classes.text}>
            <h2>A <b>Medical Clinic</b> <br className={classes.brOne} /> Owned By <b>medical<br className={classes.brTwo} /> professionals</b></h2>
            {agDets}
          </div>
        </div>
      </div>
      
      <div className={classes.curveRev}></div>
      <div className={[classes.bgBlock, "uk-background-cover"].join(' ')} data-uk-parallax="bgy: -150" style={{ backgroundImage: "url(" + [plants] + ")" }}>
        <h3>What is CBD?</h3></div>
      
      <div id="whatIsCBD" className={classes.lineRow3}>
        <div className={classes.med3Def}>
          <div className={classes.fullInline}>
            <div className={classes.inLine1}>
              <p className={classes.smallMarg}>CBD, short for <b>cannabidiol,</b> is an active compound found in the cannabis plant. CBD is extracted from hemp, and can be used in a variety of products.</p>
              <p><b>Our CBD only contains traces of up to 0.3% of THC.</b> While it is one of hundreds of components of marijuana, it <b>does not cause a “high”</b> on its own.</p>
              <div className={classes.faqLinkRow}><Link to="/faqs" className={classes.LinkB}>Visit our FAQ's to learn more <span uk-icon="icon: arrow-right"></span></Link></div>
            </div>
            
            <div className={classes.inLine2}><img src={prod} alt="Receptra CBD oil" className={classes.imgResponsive} /></div>
            
            <div className={classes.inLine3}>
              <div className={classes.row}><h3>How does <b>CBD </b> work?</h3><p className={classes.first}>CBD reacts with the body through the <b>The Endocannabinoid System</b> (ECS), which is made up of millions of cannabinoid receptor sites.</p></div>
              <div className={classes.row}><p><b>The ECS helps to regulate immune function, sleep, mood and anxiety, motor control, 
                pain, memory, appetite and metabolism, reproduction, and much more.</b></p></div>
            </div>
          </div>
        </div>
      </div>
      
      
      <div className={classes.layoutRowB}>
        <div className={classes.medDef}>
          <div className={classes.right}>
            <div className={classes.textBoxh3}><h3>Our <br/><b>Products</b></h3></div>
            <img src={layoutImg1} alt="Variety of CBD products sold at All Green" className={classes.imgResponsive} />
          </div>
          
          <div className={classes.left}>
            <img src={home1} alt="All Green Alabama Medical clinic door" className={classes.imgResponsive} />
            <div className={classes.textBox}>
              <p>Our medical team is very selective about the brands and products we choose to carry. 
                We offer a variety of different CBD solutions including topicals, oils and extracts, and skin care products.
                We also carry pet products for your furry best friends!
              </p>
              <div className={classes.clearBorder}></div><div className={classes.clearfix}></div>
              <Link to="/products" className={[classes.agLink, classes.centerr].join(' ')}>Learn More</Link>
            </div>
            <div className={classes.mobImg}><img src={home1} alt="Group of different Receptra products" className={classes.imgResponsive} /></div>
          </div>
        </div>
      </div>
      
      {/* <div className={classes.bottomRow}>
        <div className={classes.med2Def}>
          <div className={classes.image}><img src={home2} alt="girl with oil" className={classes.imgResponsive} /></div>
          <div className={classes.text}>
            <p>We do not offer CBD that can be inhaled. Inhalation of vapor oils and chemical byproducts carry unknown health risks, and is therefore, not recommended.</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default home;
