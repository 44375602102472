import React from "react";
import Social from "../../hoc/Social/Social";
import TemporaryDrawer from "../FtDrawer/FtDrawer";
// import NavItems from "../../components/NavItems/NavItems.js";
const classes = require('./Footer.module.scss');
const logo = require('../../assets/images/allGreenLogo-lt.png');

const footer = () => {
  return (
    <div className={classes.Footer}>
      <div className={classes.TopFooter}>
        <div className={classes.medDef}>
          <div className={[classes.ftCol, classes.logoCol].join(' ')}>
            <img src={logo} alt="All Green Alabama Medical logo" className={classes.imgResponsive} />
          </div>
          
          <div className={[classes.ftCol, classes.contact2Col].join(' ')}>
            <ul>
              <li className={classes.inlineLi}>1600 N. McKenzie Street, Foley, Al 36535</li>
                <li className={classes.inlineLi}><a href="tel: +12519432980">251-943-2980</a></li>
            </ul>
          </div>
          
          <div className={[classes.ftCol, classes.contact1Col].join(' ')}>
            <ul>
              <li className={classes.inlineLi}>allgreenalabamamedical@gmail.com</li>
              <li className={classes.inlineLi}><Social /></li>
            </ul>
          </div>
          
        </div>
      </div>
      
      <div className={classes.copyFooter}>
        <div className={classes.med2Def}>
          <div className={classes.copyHs}><p>Copyright {(new Date().getFullYear())} All Green Alabama Medical. All rights reserved.<br/> Design by <a href="http://hsdesignhouse.com" target="_blank" rel="noopener noreferrer">High Seas Design House.</a></p></div>
          <div className={classes.ftMenu}><TemporaryDrawer />  </div>
        </div>
      </div>
    </div>
  );
};

export default footer;
