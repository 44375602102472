import React from "react";
import { NavLink } from "react-router-dom";
import OpenHours from "../OpenHours/OpenHours";
import TemporaryDrawer from "../Drawer/Drawer";
const classes = require('./NavItems.module.scss');
const logo = require('../../assets/images/allGreenLogo-alt.png');

const navItems = (props) => {
  return (
    // <div uk-sticky="sel-target: .NavItems; cls-active: uk-navbar-sticky">
      <ul className={[classes.NavItems, classes[props.navType], classes.style1 ].join(' ')}>
        {/* uk-sticky */}
        <div className={classes.fwNav}>
        <li className={classes.navHome}><NavLink exact activeClassName={classes.activePath} to="/">Home</NavLink></li>
        <li className={classes.navAbout}><NavLink activeClassName={classes.activePath} to="/about">About Us</NavLink></li>
        <li className={classes.navProducts}><NavLink activeClassName={classes.activePath} to="/products">Products</NavLink></li>
        <li className={[classes.logoLink, classes.norm].join(' ')}><NavLink activeClassName={classes.activePath} to="/"><img src={logo} alt="All Green Alabama Medical Logo" className={classes.imgResponsive} /></NavLink></li>
        <li className={classes.navFaq}><NavLink activeClassName={classes.activePath} to="/faqs">FAQs</NavLink></li>
        <li className={classes.navContact}><NavLink activeClassName={classes.activePath} to="/contact">Contact Us</NavLink></li>
        </div>
        
        <div className={classes.altSpace}>
          <li className={classes.navPhoneFloat}>
            <a href="tel: +12519432980">251-943-2980</a>
          </li>
          <li className={classes.navAddress}>
            <a 
              href="https://www.google.com/maps/dir//all+green+alabama+medical/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x889a0d4d9e845cf5:0xef8b7590d0f57454?sa=X&ved=2ahUKEwi_6-CFo_nmAhULbKwKHS1VCJYQ9RcwFHoECBYQDQ" 
              target="_blank"
              rel="noopener noreferrer"
            >
              Get Directions
            </a>
          </li>
          <li className={classes.openHoursFloat}><OpenHours/></li>
          <li className={classes.mobileNav}>
            <TemporaryDrawer />  
          </li>
        </div>
      </ul>
    // </div>
  );
};

export default navItems;
