import React from "react";
import NavItems from "../NavItems/NavItems";
import { Link } from "react-router-dom";
const classes = require('./HeaderAlt.module.scss');
const logo = require('../../assets/images/allGreenLogo-alt.png');

const headerAlt = (props) => {
  return (
    <div className={[classes.haWrap, classes[props.HeaderType]].join(' ')}>
      <header className={classes.HeaderAltSec}>
        <div className={classes.logoLinkAbs}><Link to="/"><img src={logo} alt="All Green Alabama Medical Logo" className={classes.imgResponsive} /></Link></div>
          <NavItems navType={props.navType} />
      </header>
    </div>
  );
};

export default headerAlt;
