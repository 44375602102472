import React from "react";
import classes from "./PageHeaderGreen.module.scss";

const pageHeaderGreen = (props) => {
  return (
    <div className={[classes.PageHeaderGreen, classes[props.addStyle], classes[props.addStyle2] ].join(' ')}>
      {/* <div className={classes.med2Def}> */}
        <div className={classes.text}>
          {props.headerText}
        </div>
        
        <div className={classes.image}>
          {props.headerTitle ? <h1 className={classes[props.h1Class]}>{props.headerTitle}</h1> : '' }
          <img src={props.image} alt={props.imageAlt} className={classes.imgResponsive} />
        </div>
      {/* </div> */}
    </div>
  );
};

export default pageHeaderGreen;
